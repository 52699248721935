import React from 'react';

const Hero = () => (
    <div className="text-center hero my-5">
        <h1 className="mb-4">Constellix CSV to FreshSales</h1>

        <p className="lead">Please Login and navigate to "Upload CSV"</p>
    </div>
);

export default Hero;

import React from 'react';
import Logo from '../images/constellix-logo.svg';

const Footer = () => (
    <footer className="bg-light p-3 text-center">
        <div>
            <img src={Logo} alt="Constllix Logo" />
        </div>
        <p>CSV to FreshSales Application</p>
    </footer>
);

export default Footer;

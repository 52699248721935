import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Loading } from '../components';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import axios from 'axios';

import './upload.css';
const FileDownload = require('js-file-download');

const Upload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileStatus, setFileStatus] = useState(null);
    const [success, setSuccess] = useState(false);
    const { user } = useAuth0();

    const { name } = user;

    const onChangeHandler = (event) => {
        setSuccess(false);

        if (event.target.files.length === 0) {
            setSelectedFile(null);
        } else if (event.target.files[0].name.split('.')[1] !== 'csv') {
            alert('Please choose a CSV file');
        } else if (event.target.files[0].name.split('.')[1] === 'csv') {
            setFileStatus(false);
            setSelectedFile(event.target.files[0]);
        }
    };

    const onClickHandler = () => {
        const data = new FormData();
        if (selectedFile === null) {
            alert('Please choose a CSV file');
        } else {
            data.append('file', selectedFile);

            axios
                .post('https://provider.leads.server.us/uploader', data, {
                    // receive two    parameter endpoint url ,form data
                })
                .then((res) => {
                    // then print response status
                    if (res.status === 206) {
                        setFileStatus(true);
                    } else {
                        setFileStatus(false);
                        setSuccess(false);
                        setSuccess(true);
                        FileDownload(res.data, 'report.csv');
                    }
                });
        }
    };

    return (
        <Container className="mb-5">
            <h2>Welcome {name} </h2>
            <input type="file" name="file" onChange={(event) => onChangeHandler(event)} />
            {fileStatus ? (
                <p className="file-status">
                    The file you selected has incorrect data. Please make sure all data in the file is correct.
                </p>
            ) : null}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    style={{ width: '100px', marginTop: '10px' }}
                    variant="success"
                    type="button"
                    className="btn btn-block"
                    onClick={onClickHandler}
                >
                    Upload
                </Button>
                <p className={success ? 'success' : null}>{success ? 'Success' : null}</p>
            </div>
        </Container>
    );
};

// export default withAuthenticationRequired(Upload, {
//     onRedirecting: () => <Loading />,
// });
export default Upload;

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { NavBar, Footer, Loading } from './components';
import { Home, Upload } from './views';

import './app.css';

const ProtectedRoute = ({ component, ...args }) => (
    <Route component={withAuthenticationRequired(component)} {...args} />
);

const App = () => {
    const { isLoading } = useAuth0();

    if (isLoading) {
        return <Loading />;
    }
    return (
        <div id="app" className="d-flex flex-column h-100">
            <NavBar />
            <Container className="flex-grow-1 mt-5">
                <Switch>
                    <Route path="/" exact component={Home} />
                    {/* <Route path="/upload" exact component={Upload} /> */}
                    <ProtectedRoute path="/upload" component={Upload} />
                </Switch>
            </Container>
            <Footer />
        </div>
    );
};

export default App;
